// Contains the inventory, i.e. the count of items of each productId (one entry per productId and size)
export const tkInventory = [
    {productId: 1, size: 44, count:5, reservedCount:3},
    {productId: 1, size: 45, count:5, reservedCount:3},
    {productId: 1, size: 46, count:5, reservedCount:3},
    {productId: 1, size: 47, count:5, reservedCount:3},
    {productId: 1, size: 48, count:5, reservedCount:3},
    {productId: 1, size: 49, count:5, reservedCount:3},
    {productId: 2, size: 44, count:5, reservedCount:3},
    {productId: 2, size: 45, count:5, reservedCount:3},
    {productId: 2, size: 46, count:5, reservedCount:3},
    {productId: 2, size: 47, count:5, reservedCount:3},
    {productId: 2, size: 48, count:5, reservedCount:3},
    {productId: 2, size: 49, count:5, reservedCount:3},
    {productId: 3, size: 44, count:5, reservedCount:3},
    {productId: 3, size: 45, count:5, reservedCount:3},
    {productId: 3, size: 46, count:5, reservedCount:3},
    {productId: 3, size: 47, count:5, reservedCount:3},
    {productId: 3, size: 48, count:5, reservedCount:3},
    {productId: 3, size: 49, count:5, reservedCount:3},
    {productId: 4, size: 44, count:5, reservedCount:3},
    {productId: 4, size: 45, count:5, reservedCount:3},
    {productId: 4, size: 46, count:5, reservedCount:3},
    {productId: 4, size: 47, count:5, reservedCount:3},
    {productId: 4, size: 48, count:5, reservedCount:3},
    {productId: 4, size: 49, count:5, reservedCount:3},
    {productId: 5, size: 44, count:5, reservedCount:3},
    {productId: 5, size: 45, count:5, reservedCount:3},
    {productId: 5, size: 46, count:5, reservedCount:3},
    {productId: 5, size: 47, count:5, reservedCount:3},
    {productId: 5, size: 48, count:5, reservedCount:3},
    {productId: 5, size: 49, count:5, reservedCount:3},
]

export const SET_LIST='SET_TE_LIST'

export const setList = (list) => {
  return {
    type: SET_LIST,
    list,
  }
}






export const SET_APP_BAR_BACKGROUND='SET_APP_BAR_BACKGROUND'

export const setGlobalStyle = (payload) => {
  return {
    type: SET_APP_BAR_BACKGROUND,
    payload,
  }
}


